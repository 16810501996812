import companies from "../apis/agentApi";
import {
  SELECTED_COMPANY,
  CREATE_COMPANY,
  FETCH_COMPANIES,
  FETCH_COMPANY,
  EDIT_COMPANY,
  DELETE_COMPANY,
} from "./types";
import history from "../history";

// Start Company
export const selectedCompany = (company) => {
  return {
    type: SELECTED_COMPANY,
    payload: company,
  };
};

export const createCompany = (formValues) => async (dispatch) => {
  const response = await companies.post("api/companies", formValues);

  dispatch({ type: CREATE_COMPANY, payload: response.data });
  history.push("/company/list");
};

export const fetchCompanies = () => async (dispatch) => {
  const response = await companies.get("api/companies");

  dispatch({ type: FETCH_COMPANIES, payload: response.data });
};

export const fetchCompany = (id) => async (dispatch) => {
  const response = await companies.get(`api/companies/${id}`);

  dispatch({ type: FETCH_COMPANY, payload: response.data });
};

export const editCompany = (id, formValues) => async (dispatch) => {
  // Customize axios instance for patch
  let axiosConfig = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };
  // End //

  const response = await companies.patch(
    `api/companies/${id}`,
    formValues,
    axiosConfig
  );

  dispatch({ type: EDIT_COMPANY, payload: response.data });
  history.push("/company/list");
};

export const deleteCompany = (id) => async (dispatch) => {
  await companies.delete(`api/companies/${id}`);

  dispatch({ type: DELETE_COMPANY, payload: id });
};
