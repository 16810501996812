import _ from "lodash";
import {
  SELECTED_REPORT,
  CREATE_REPORT,
  FETCH_REPORTS,
  FETCH_REPORT,
  EDIT_REPORT,
  DELETE_REPORT,
} from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case SELECTED_REPORT:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_REPORTS:
      return { ...state, ..._.mapKeys(action.payload["hydra:member"], "id") };
    case FETCH_REPORT:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_REPORT:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_REPORT:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_REPORT:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};
