import _ from "lodash";
import { FETCH_ADDRESSES, FETCH_ADDRESS } from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ADDRESSES:
      return { ...state, ..._.mapKeys(action.payload["hydra:member"], "id") };
    case FETCH_ADDRESS:
      return { ...state, [action.payload.id]: action.payload };

    default:
      return state;
  }
};
