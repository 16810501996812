import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
import { Button, Grid } from "semantic-ui-react";
import {
  fetchReports,
  fetchUsers,
  fetchCompanies,
  selectedReport,
} from "../../actions";

import "react-table-v6/react-table.css";

import ReportModalClass from "./ReportModalClass";
import OrderServiceModalClass from "./OrderServiceModalClass";

class ReportTableGrid extends React.Component {
  componentDidMount() {
    if (this.props.reports.length === 0) {
      this.props.fetchUsers();
      this.props.fetchReports();
      this.props.fetchCompanies();
    }
  }

  renderGrid() {
    const onRowClick = (state, rowInfo) => {
      return {
        onClick: (e) => {
          this.props.selectedReport(rowInfo.original);
        },
      };
    };

    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const today = new Date();

    const { userData, currentUserId, reports } = this.props;

    var arrayAnteriores = [];

    if (!reports) {
      return <div className="ui active centered inline loader" />;
    }

    const empUserFind = [];
    userData.map((user) => {
      if (user.id === currentUserId) {
        empUserFind.push(user.companies);
      }
    });    

    const findCompaniesFromUser = _.map(_.map(empUserFind[0]), "id");

    const findCompaniesFromReport = _.map(reports);

    // const reportData = _.filter(_.map(reports));

    // const reportData = [];
    // _.forEach(findCompaniesFromUser, function (empUser) {
    //   _.forEach(findCompaniesFromReport, function (listReport) {
    //     // if (listReport.company.id === empUser)
    //     console.log(listReport.company.id);
    //   });
    //   // if (_.map(reports, "company.id") === empUser) {
    //   //   reportData.push(findCompaniesFromReport);
    //   // }
    //
    //   console.log(empUser);
    // });

    const reportData = [];
    for (var i = 0; i < findCompaniesFromUser.length; i++) {
      var empUser = findCompaniesFromUser[i];
      //console.log(empUser);

      for (var j = 0; j < findCompaniesFromReport.length; j++) {
        if (findCompaniesFromReport[j].company.id == empUser && findCompaniesFromReport[j].isReleased) {
          reportData.push(findCompaniesFromReport[j]);
        }
      }
    }

    var j = 0;
        for (var i=0; i < this.props.reports.length; i++){
          if(this.props.reports[i].oldReport){
            arrayAnteriores[j] = {id:this.props.reports[i].oldReport.id};
            j++;
          }
        }

    //console.log("FINALSZ", findCompaniesFromReport);

    return (
      <>
        <br />
        <ReactTable
          keyField="id"
          getTdProps={onRowClick}
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={reportData}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Empresa",
              accessor: "company.fantasyName",
            },
            {
              Header: "Sigla",
              accessor: "company.initials",
            },
            {
              Header: "Título do Laudo",
              accessor: "title",
              Cell: (rowInfo) => {
                if(rowInfo.original.isDeleted){
                  const tituloDel = "D- ";
                  const titulo = rowInfo.value;
                  return <span><span style={{color:'red', fontWeight:'bold'}} >{tituloDel}</span>{titulo}</span>;
                }else{
                  const titulo = rowInfo.value;
                  return <span>{titulo}</span>;
                }
                
              },
              // maxWidth: 580,
            },
            {
              Header: "Ordem de Serviço",
              accessor: "orderService.title",
            },
            {
              Header: "Emissão",
              id: "startDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.startDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {   

                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
            },
            {
              Header: "Validade",
              id: "endDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.endDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {  

                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
              getProps: (state, rowInfo) => ({
                style: {
                  backgroundColor:
                    today > new Date(rowInfo.original.endDate)
                      ? "#fbd9d3"
                      : null,
                },
              }),
            },
            {
              Header: "Tipo de Serviço",
              id: "services",
              // maxWidth: 200,
              accessor: (d) =>
                d.services.map((service) => {
                  return service.name;
                }),
              Cell: (props) =>
                props.original.services.map((service, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{service.name}</span>
                      <br />
                    </div>
                  );
                }),
            },
            {
              Header: "Tipo de Equipamento",
              id: "equipments",
              // maxWidth: 200,
              accessor: (d) =>
                d.equipments.map((equipment) => {
                  return equipment.name;
                }),
              Cell: (props) =>
                props.original.equipments.map((equipment, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{equipment.name}</span>
                      <br />
                    </div>
                  );
                }),
            },
            // {
            //   Header: "Status",
            //   columns: [
            //     {
            //       id: "isApproved",
            //       filterable: false,
            //       maxWidth: 70,
            //       accessor: (d) => {
            //         return d.isApproved ? (
            //           <Button
            //             as="a"
            //             color="teal"
            //             size="mini"
            //             icon="thumbs up"
            //             target="_blank"
            //             rel="noreferrer"
            //           />
            //         ) : (
            //           <Button
            //             as="a"
            //             color="red"
            //             size="mini"
            //             icon="thumbs down"
            //             // href={apiUrl + `/${e.file}`}
            //             target="_blank"
            //             rel="noreferrer"
            //           />
            //         );
            //       },
            //     },
            //   ],
            // },
            {
              Header: "Status",
              accessor: "isApproved",
              maxWidth: 100,
              sortable: false,
              id: "isApproved",
              Cell: (props) =>
                props.value === true ? (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                       // color="teal"
                        size="mini"
                        icon="thumbs up"
                       // target="_blank"
                       // rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="teal"
                        size="mini"
                        icon="thumbs up"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ) : (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                       // color="red"
                        size="mini"
                        icon="thumbs down"
                       // target="_blank"
                       // rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="red"
                        size="mini"
                        icon="thumbs down"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id] === true;
                }
                if (filter.value === "false") {
                  return row[filter.id] === false;
                }
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Todos</option>
                  <option value="true">Assinado</option>
                  <option value="false">Não Assinado</option>
                </select>
              ),
            },
            {
              Header: "Condição",
              accessor: "isNotSatisfactory",
              // filterable: false,
              sortable: false,
              maxWidth: 100,
              id: "isNotSatisfactory",
              Cell: (props) =>
                props.original.isNotSatisfactory === true ? (

                  (_.find(arrayAnteriores, {id:props.original.id})) ? (
                    props.original.isDeleted ? (
                    <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                         // color="purple"
                          size="mini"
                          icon="bug"
                        //  target="_blank"
                        //  rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    ) : (
                      <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                          color="purple"
                          size="mini"
                          icon="bug"
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    )
                  ) : (
                    props.original.isDeleted ? (
                    <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                        //  color="red"
                          size="mini"
                          icon="bug"
                        //  target="_blank"
                        //  rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    ) : (
                      <Grid>
                      <Grid.Column textAlign="center">
                        <Button
                          as="a"
                          color="red"
                          size="mini"
                          icon="bug"
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Grid.Column>
                    </Grid>
                    )
                  )
                ) : (
                  props.original.isDeleted ? (
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                      //  color="green"
                        size="mini"
                        icon="shield alternate"
                      //  target="_blank"
                      //  rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  ) : (
                    <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                        as="a"
                        color="green"
                        size="mini"
                        icon="shield alternate"
                        target="_blank"
                        rel="noreferrer"
                      />
                    </Grid.Column>
                  </Grid>
                  )
                ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id] === true;
                }
                if (filter.value === "false") {
                  return row[filter.id] === false;
                }
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Todos</option>
                  <option value="false">Satisfatório</option>
                  <option value="true">Insatisfatório</option>
                </select>
              ),
            },
            {
              Header: "Ações",
              columns: [
                {
                  id: "file.id",
                  filterable: false,
                  maxWidth: 280,
                  minWidth: 100,
                  width:210,
                  sortable: false,
                  accessor: (e) => (
                    e.isDeleted ? (
                      <Button
                        className="ui left labeled mini icon button"
                        icon="eye slash outline"                        
                        content="Excluído - motivo técnico"
                      />
                    ) : (
                      <ReportModalClass
                      id={e.id}
                      title={e.title}
                      startDate={e.startDate}
                      endDate={e.endDate}
                      observation={e.observation}
                      file={e.file}
                      isApproved={e.isApproved}
                      movimentations={e.movimentations}
                    />
                    )
                  ),
                },
               /* {
                  id: "orderService.id",
                  filterable: false,
                  maxWidth: 100,
                  minWidth: 95,
                  accessor: (e) =>
                    e.orderService ? (
                      <OrderServiceModalClass
                        id={e.orderService.id}
                        orderService={e.orderService.title}
                        //startDate={e.orderService.startDate}
                        //endDate={e.orderService.endDate}
                        observation={e.orderService.observation}
                        file={e.orderService.file}
                        //isApproved={e.isApproved}
                        //movimentations={e.movimentations}
                      />
                    ) : (
                      ""
                    ),
                }, */
              ],
            },
          ]}
        />
      </>
    );
  }
  

  render() {

    const styleDel = {
      color: 'red',  
      fontWeight: 'bold',  
      width: '8em',
      marginLeft: '0.2em',
      backgroundColor:'#F9F463',
      border: '1px',
      borderRadius: '4px',
      padding: '0.5em',
      height:'0.8em',
      fontSize: '10px',

    };

    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <div className="ui row">
            <div className="sixteen wide column left bottom aligned content">
              <h1>Laudos</h1>
            </div>
            <div className="sixteen wide column right bottom aligned content">
              <div className="ui label mini teal">
                <i className="thumbs up icon" />
                Assinado
              </div>

              <div className="ui label mini red ">
                <i className="thumbs down icon" />
                Não assinado
              </div>
              <span> 
                <span style = {styleDel}>D- Deletado</span>
              </span>
            </div>
          </div>
          <div className="ui fifteen wide column">
            <div className="ui divider" />
          </div>
        </div>
        <div className="ui fifteen wide column">{this.renderGrid()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    reports: _.orderBy(Object.values(state.reports), "endDate", "desc"),
    userName: _.map(
      Object.values(state.reports),
      "movimentations[0].user.name"
    ),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchUsers,
    fetchCompanies,
    fetchReports,
    selectedReport,
  })(ReportTableGrid)
);
