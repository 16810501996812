import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { userLogout } from "../actions";

class Logout extends React.Component {
  componentDidMount() {
    this.props.userLogout();
    this.props.history.push("/");
    window.location.reload();
  }

  render() {
    return (
      <>
        <div className="ui active centered inline loader" />
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "logoutForm",
  // validate,
})(Logout);

export default connect(null, { userLogout })(formWrapped);
