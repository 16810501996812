import contracts from "../apis/agentApi";
import {
  SELECTED_CONTRACT,
  CREATE_CONTRACT,
  FETCH_CONTRACTS,
  FETCH_CONTRACT,
  EDIT_CONTRACT,
  DELETE_CONTRACT,
} from "./types";
import history from "../history";

// Start Contract
export const selectedContract = (contract) => {
  return {
    type: SELECTED_CONTRACT,
    payload: contract,
  };
};

export const createContract = (formValues) => async (dispatch) => {
  const response = await contracts.post("api/contracts", formValues);

  dispatch({ type: CREATE_CONTRACT, payload: response.data });
  history.push("/contract/list");
};

export const fetchContracts = () => async (dispatch) => {
  const response = await contracts.get("api/contracts");

  dispatch({ type: FETCH_CONTRACTS, payload: response.data });
};

export const fetchContract = (id) => async (dispatch) => {
  const response = await contracts.get(`api/contracts/${id}`);

  dispatch({ type: FETCH_CONTRACT, payload: response.data });
};

export const editContract = (id, formValues) => async (dispatch) => {
  // Customize axios instance for patch
  let axiosConfig = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };
  // End //

  const response = await contracts.patch(
    `api/contracts/${id}`,
    formValues,
    axiosConfig
  );

  dispatch({ type: EDIT_CONTRACT, payload: response.data });
  history.push("/contract/list");
};

export const deleteContract = (id) => async (dispatch) => {
  await contracts.delete(`api/contracts/${id}`);

  dispatch({ type: DELETE_CONTRACT, payload: id });
};
