import React from "react";
import requireAuth from "../auth/requireAuth";
import agentApi from "../../apis/agentApi";

class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newPassword: "",
      confirmNewPassword: "",
      errorMessage: "",
      passwordNotMatch: "",
      successMessage: "",
      visible: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { password, newPassword, confirmNewPassword } = this.state;

    if (newPassword !== confirmNewPassword) {
      this.setState({ passwordNotMatch: "As senhas não são iguais!" });
    } else {
      const data = {
        password: password,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      };

      agentApi
        .post(`/api/change-password`, data)
        .then((res) => {
          if (res.status === 204) {
            this.setState({
              password: res.data.password,
              newPassword: res.data.newPassword,
              confirmNewPassword: res.data.confirmNewPassword,
              successMessage:
                "Sua senha foi alterada com sucesso, você pode fechar o formulário.",
            });
          }
          return null;
        })
        .catch((err) => {
          this.setState({
            errorMessage: "Alguma coisa deu errada, verifique sua senha atual!",
          });
        });
    }
  };

  render() {
    return (
      <div className="ui column">
        {this.state.errorMessage && (
          <div className="ui negative message">
            <div className="header">{this.state.errorMessage}</div>
            <p>Verifique a digitação e tente novamente.</p>
          </div>
        )}

        {this.state.passwordNotMatch && (
          <div className="ui warning message">
            <div className="header">{this.state.passwordNotMatch}</div>
            <p>Verifique a digitação e tente novamente.</p>
          </div>
        )}
        <form className="ui form" onSubmit={this.handleSubmit}>
          <div className="field">
            <label>Senha atual</label>
            <input
              type="password"
              placeholder="Senha atual"
              name="password"
              onChange={this.handleChange}
            />
          </div>
          {!this.state.password ? (
            <div className="disabled field">
              <label>Nova senha</label>
              <input
                type="password"
                placeholder="Nova senha"
                name="newPassword"
                onChange={this.handleChange}
              />
            </div>
          ) : (
            <div className="field">
              <label>Nova senha</label>
              <input
                type="password"
                placeholder="Nova senha"
                name="newPassword"
                onChange={this.handleChange}
              />
            </div>
          )}
          {!this.state.password ? (
            <div className="disabled field">
              <label>Repita a nova senha</label>
              <input
                type="password"
                placeholder="Repita nova senha"
                name="confirmNewPassword"
                onChange={this.handleChange}
              />
            </div>
          ) : (
            <div className="field">
              <label>Repita a nova senha</label>
              <input
                type="password"
                placeholder="Repita nova senha"
                name="confirmNewPassword"
                onChange={this.handleChange}
              />
            </div>
          )}
          {!this.state.password ? (
            <button className="ui disabled button yellow" type="submit">
              Enviar nova senha
            </button>
          ) : (
            <button className="ui button yellow" type="submit">
              Enviar nova senha
            </button>
          )}
        </form>
        {this.state.successMessage && (
          <div className="ui positive message">
            <div className="header">{this.state.successMessage}</div>
            <p>Grato por estar conosco.</p>
          </div>
        )}
      </div>
    );
  }
}

export default requireAuth(AccountForm);
