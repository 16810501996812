import React from "react";
import { Link } from "react-router-dom";
import { Button, Header, Modal } from "semantic-ui-react";

import agentApi from "../../apis/agentApi";

class RememberPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      code: "",
      password: "",
      confirmPassword: "",
      errorMessage: "",
      errorMessageReset: "",
      errorMessageCode: "",
      passwordNotMatch: "",
      successMessage: "",
      visible: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => {
    this.setState({ modalOpen: false, state: [] });
    window.location.reload();
  };

  componentDidMount() {
    const { startOpen } = this.props;

    if (startOpen) {
      this.handleOpen();
    }
  }

  handleChangeToken = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSubmitToken = async (e) => {
    e.preventDefault();

    const data = {
      email: this.state.email,
    };

    agentApi
      .post("/reset-password", data)
      .then((res) => {
        this.setState({ token: res.data.token });
      })
      .catch((err) => {
        this.setState({ errorMessage: "E-mail não encontrado!" });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { code, password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      this.setState({ passwordNotMatch: "As senhas não são iguais!" });
    } else {
      const data = {
        code: code,
        password: password,
        confirmPassword: confirmPassword,
      };

      agentApi
        .post(`/reset-password/${this.state.token}`, data)
        .then((res) => {
          if (res.status === 204) {
            this.setState({
              successMessage:
                "Sua senha foi alterada com sucesso, você pode fechar o formulário.",
            });
          }
          return null;
        })
        .catch((err) => {
          this.setState({
            errorMessageCode: "O código parece não estar correto!",
          });
        });
    }
  };

  render() {
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        trigger={
          <Link className="ui left labeled red tiny icon ">
            Esqueceu sua Senha!
          </Link>
        }
      >
        <Header icon="hand point right" content="Solicitar nova senha." />
        <Modal.Content>
          <p>
            Informe seu e-mail de cadastro para iniciar o processo de
            recuperação de senha.
          </p>

          {this.state.errorMessage && (
            <div className="ui negative message">
              <div className="header">{this.state.errorMessage}</div>
              <p>Verifique a digitação e tente novamente.</p>
            </div>
          )}

          <form className="ui form" onSubmit={this.handleSubmitToken}>
            <div className="field">
              <label>E-mail</label>
              <input
                type="email"
                placeholder="E-mail cadastrado no Sistema de Laudo G7"
                name="email"
                onChange={this.handleChangeToken}
              />
            </div>

            {!this.state.token ? (
              <button className="ui button yellow" type="submit">
                Solicitar nova senha
              </button>
            ) : (
              <button className="ui button yellow disabled" type="submit">
                Solicitar nova senha
              </button>
            )}
          </form>
          <br />
          <br />
          {/*++++++++++++++++*/}
          <p>Informe o código que você recebeu em seu email e a nova senha.</p>

          {this.state.token && (
            <div className="ui positive message">
              <div className="header">Verifique seu email!</div>
              <p>Enviamos um código que você deve usar abaixo.</p>
            </div>
          )}
          {this.state.passwordNotMatch && (
            <div className="ui warning message">
              <div className="header">{this.state.passwordNotMatch}</div>
              <p>Verifique a digitação e tente novamente.</p>
            </div>
          )}
          <form className="ui form" onSubmit={this.handleSubmit}>
            {!this.state.token ? (
              <div className="disabled field">
                <label>Código</label>
                <input
                  type="text"
                  placeholder="Código recebido no e-mail"
                  name="code"
                  onChange={this.handleChange}
                />
              </div>
            ) : (
              <div className="field">
                <label>Código</label>
                <input
                  type="text"
                  placeholder="Código recebido no e-mail"
                  name="code"
                  onChange={this.handleChange}
                />
              </div>
            )}
            {!this.state.token ? (
              <div className="disabled field">
                <label>Nova senha</label>
                <input
                  type="password"
                  placeholder="Nova senha"
                  name="password"
                  onChange={this.handleChange}
                />
              </div>
            ) : (
              <div className="field">
                <label>Nova senha</label>
                <input
                  type="password"
                  placeholder="Nova senha"
                  name="password"
                  onChange={this.handleChange}
                />
              </div>
            )}
            {!this.state.token ? (
              <div className="disabled field">
                <label>Repita a nova senha</label>
                <input
                  type="password"
                  placeholder="Nova senha"
                  name="confirmPassword"
                  onChange={this.handleChange}
                />
              </div>
            ) : (
              <div className="field">
                <label>Repita a nova senha</label>
                <input
                  type="password"
                  placeholder="Nova senha"
                  name="confirmPassword"
                  onChange={this.handleChange}
                />
              </div>
            )}
            {!this.state.token ? (
              <button className="ui disabled button yellow" type="submit">
                Enviar nova senha
              </button>
            ) : (
              <button className="ui button yellow" type="submit">
                Enviar nova senha
              </button>
            )}
          </form>
          {this.state.errorMessageCode && (
            <div className="ui warning message">
              <div className="header">{this.state.errorMessageCode}</div>
              <p>Verifique seu e-mail.</p>
            </div>
          )}
          {this.state.successMessage && (
            <div className="ui positive message">
              <div className="header">{this.state.successMessage}</div>
              <p>Grato por estar conosco.</p>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button icon="close" color="grey" onClick={this.handleClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default RememberPassword;
