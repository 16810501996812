import users from "../apis/agentApi";
import {
  CREATE_USER,
  FETCH_USERS,
  FETCH_USER,
  EDIT_USER,
  DELETE_USER,
} from "./types";
import history from "../history";

// Start User
export const createUser = (formValues) => async (dispatch) => {
  const response = await users.post("/users", formValues);

  dispatch({ type: CREATE_USER, payload: response.data });
  history.push("/user/list");
};

export const fetchUsers = () => async (dispatch) => {
  const response = await users.get("/api/users");

  dispatch({ type: FETCH_USERS, payload: response.data });
};

export const fetchUser = (id) => async (dispatch) => {
  const response = await users.get(`/api/users/${id}`);

  dispatch({ type: FETCH_USER, payload: response.data });
};

export const editUser = (id, formValues) => async (dispatch) => {
  const response = await users.patch(`/api/users/${id}`, formValues);

  dispatch({ type: EDIT_USER, payload: response.data });
  history.push("/user/list");
};

export const deleteUser = (id) => async (dispatch) => {
  await users.delete(`/api/users/${id}`);

  dispatch({ type: DELETE_USER, payload: id });
};
