import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import authMiddleware from "./middlewares/authMiddleware";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import history from "./history";

import App from "./components/App";

// import requireAuth from "./components/auth/requireAuth";

import "semantic-ui-css/semantic.min.css";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "Hold",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [];
middlewares.push(...[reduxThunk, authMiddleware]);

const store = createStore(
  persistedReducer,
  {
    auth: { isAuthenticated: localStorage.getItem("token") },
  },
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

ReactDOM.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </PersistGate>,
  document.querySelector("#root")
);
