import reports from "../apis/agentApi";
import {
  SELECTED_REPORT,
  CREATE_REPORT,
  FETCH_REPORTS,
  FETCH_REPORT,
  EDIT_REPORT,
  DELETE_REPORT,
} from "./types";
import history from "../history";

// Start Report
export const selectedReport = (report) => {
  return {
    type: SELECTED_REPORT,
    payload: report,
  };
};

export const createReport = (formValues) => async (dispatch) => {
  const response = await reports.post("/api/reports", formValues);

  dispatch({ type: CREATE_REPORT, payload: response.data });
  history.push("/report/list");
};

export const fetchReports = () => async (dispatch) => {
  const response = await reports.get("/api/reports");

  dispatch({ type: FETCH_REPORTS, payload: response.data });
};

export const fetchReport = (id) => async (dispatch) => {
  const response = await reports.get(`/api/reports/${id}`);

  dispatch({ type: FETCH_REPORT, payload: response.data });
};

export const editReport = (id, formValues) => async (dispatch) => {
  // Customize axios instance for patch
  let axiosConfig = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };
  // End //

  const response = await reports.patch(
    `/api/reports/${id}`,
    formValues,
    axiosConfig
  );

  dispatch({ type: EDIT_REPORT, payload: response.data });
  history.push("/report/list");
};

export const deleteReport = (id) => async (dispatch) => {
  await reports.delete(`/api/reports/${id}`);

  dispatch({ type: DELETE_REPORT, payload: id });
};
