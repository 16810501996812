import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { userLoginAttempt } from "../actions";

import RememberPassword from "./forgotpassword/RememberPassword";

import logo from "../logo_g7spr.svg";

class Home extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      // console.log(prevProps);
      // console.log(this.props);
      this.props.history.push("report/list");
      // this.props.history.push("/dashboard");
    }
  }

  onSubmit = (values) => {
    return this.props.userLoginAttempt(values.username, values.password);
  };

  render() {
    const { handleSubmit, error } = this.props;

    return (
      <div className="ui middle aligned center aligned grid">
        <div className="ui nine wide column">
          <h2 className="ui grey image header">
            <img src={logo} className="image" alt="#" />
            <div className="content">Sistema de Laudos</div>
          </h2>
          <br />
          <br />
          {error && (
            <div className="ui negative message">
              <div className="header">{error}</div>
              <p>Verifique suas credencias.</p>
            </div>
          )}
          <form
            name="login"
            className="ui form"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <div className="field">
              <label className="ui left aligned header">Usuário</label>
              <Field
                type="text"
                name="username"
                component="input"
                // autoComplete="off"
              />
            </div>
            <div className="field">
              <label className="ui left aligned header">Senha</label>
              <Field type="password" name="password" component="input" />
            </div>
            <button className="ui fluid large yellow submit button">
              Login
            </button>
          </form>
          <div className="ui message">
            <RememberPassword />
          </div>
        </div>
      </div>
    );
  }
}

const formWrapped = reduxForm({
  form: "loginForm",
  // validate,
})(Home);

const mapStateToProps = (state) => ({
  ...state.auth,
});

export default connect(mapStateToProps, { userLoginAttempt })(formWrapped);
