import _ from "lodash";
import {
    CREATE_COMPANY,
    DELETE_COMPANY,
    EDIT_COMPANY,
    FETCH_COMPANIES,
    FETCH_COMPANY,
    SELECTED_COMPANY,
} from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case SELECTED_COMPANY:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_COMPANIES:
      return { ...state, ..._.mapKeys(action.payload["hydra:member"], "id") };
    case FETCH_COMPANY:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_COMPANY:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_COMPANY:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_COMPANY:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};
