import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
// import { Button } from "semantic-ui-react";
import {
  fetchContracts,
  fetchUsers,
  fetchCompanies,
  selectedContract,
} from "../../actions";

import "react-table-v6/react-table.css";

import ContractModalClass from "./ContractModalClass";

class ContractTableGrid extends React.Component {
  componentDidMount() {
    if (this.props.contracts.length === 0) {
      this.props.fetchUsers();
      this.props.fetchContracts();
      this.props.fetchCompanies();
    }
  }

  renderGrid() {
    // console.log(this.props.contracts);
    const onRowClick = (state, rowInfo) => {
      return {
        onClick: (e) => {
          this.props.selectedContract(rowInfo.original);
        },
      };
    };

    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const today = new Date();

    const { userData, currentUserId, contracts } = this.props;

    if (!contracts) {
      return <div className="ui active centered inline loader" />;
    }

    const empUserFind = [];
    userData.map((user) => {
      if (user.id === currentUserId) {
        empUserFind.push(user.companies);
      }
    });

    /*const contractList = contracts.filter(function (item) {
      return item.company.document === empUserFind[0];
    }); */

    const findCompaniesFromUser = _.map(_.map(empUserFind[0]), "id");

    const findCompaniesFromContract = _.map(contracts);

    const contractData = [];
    for (var i = 0; i < findCompaniesFromUser.length; i++) {
      var empUser = findCompaniesFromUser[i];
      console.log(empUser);

      for (var j = 0; j < findCompaniesFromContract.length; j++) {
        if (findCompaniesFromContract[j].company.id == empUser) {
          contractData.push(findCompaniesFromContract[j]);
        }
      }
    }

    return (
      <div>
        <br />
        <ReactTable
          keyField="id"
          getTdProps={onRowClick}
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={contractData}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Empresa",
              accessor: "company.fantasyName",
            },
            {
              Header: "Título do Contrato",
              accessor: "title",
            },
            {
              Header: "Início",
              id: "startDate",
              maxWidth: 100,
              accessor: (d) => {
                return ptBRFormatter.format(new Date(d.startDate));
              },
            },
            {
              Header: "Término",
              id: "endDate",
              maxWidth: 90,
              accessor: (d) => {
                return ptBRFormatter.format(new Date(d.endDate));
              },
              Cell: (props) => {
                const custom_date = props.value;
                return <span>{custom_date}</span>;
              },
              getProps: (state, rowInfo) => ({
                style: {
                  backgroundColor:
                    today > new Date(rowInfo.original.endDate)
                      ? "#fbd9d3"
                      : null,
                },
              }),
            },
            // {
            //   Header: "Status",
            //   columns: [
            //     {
            //       id: "isApproved",
            //       filterable: false,
            //       maxWidth: 70,
            //       accessor: (d) => {
            //         return d.isApproved ? (
            //           <Button
            //             as="a"
            //             color="teal"
            //             size="mini"
            //             icon="thumbs up"
            //             target="_blank"
            //             rel="noreferrer"
            //           />
            //         ) : (
            //           <Button
            //             as="a"
            //             color="red"
            //             size="mini"
            //             icon="thumbs down"
            //             // href={apiUrl + `/${e.file}`}
            //             target="_blank"
            //             rel="noreferrer"
            //           />
            //         );
            //       },
            //     },
            //   ],
            // },
            {
              Header: "Ações",
              columns: [
                {
                  id: "file.id",
                  filterable: false,
                  maxWidth: 170,
                  accessor: (e) => (
                    <ContractModalClass
                      id={e.id}
                      title={e.title}
                      startDate={e.startDate}
                      endDate={e.endDate}
                      observation={e.observation}
                      file={e.file}
                      isApproved={e.isApproved}
                    />
                  ),
                },
              ],
            },
          ]}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <div className="ui row">
            <div className="ui sixteen wide column left bottom aligned content">
              <h1>Contratos</h1>
            </div>
          </div>
          <div className="ui fifteen wide column">
            <div className="ui divider" />
          </div>
        </div>

        <div className="ui fifteen wide column">{this.renderGrid()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    contracts: _.orderBy(Object.values(state.contracts), "endDate", "desc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchUsers,
    fetchCompanies,
    fetchContracts,
    selectedContract,
  })(ContractTableGrid)
);
