import _ from "lodash";
import { FETCH_SEGMENT_TYPES } from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SEGMENT_TYPES:
      return {
        ...state,
        ..._.mapKeys(action.payload["hydra:member"], "id"),
      };

    default:
      return state;
  }
};
