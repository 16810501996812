import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import companyReducer from "./companyReducer";
import addressReducer from "./addressReducer";
import contractReducer from "./contractReducer";
import reportReducer from "./reportReducer";
import segmentTypeReducer from "./segmentTypeReducer";
import serviceTypeReducer from "./serviceTypeReducer";
import equipmentTypeReducer from "./equipmentTypeReducer";

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  users: userReducer,
  companies: companyReducer,
  address: addressReducer,
  contracts: contractReducer,
  reports: reportReducer,
  segment_types: segmentTypeReducer,
  service_types: serviceTypeReducer,
  equipment_types: equipmentTypeReducer,
});
