import React, { Component } from "react";
import { Message } from "semantic-ui-react";

class MessageIsApprovedBlock extends Component {
  state = { visible: true };

  handleDismiss = () => {
    this.setState({ visible: false });

    setTimeout(() => {
      this.setState({ visible: true });
    }, 10000);
  };

  render() {
    if (this.state.visible) {
      return (
        <Message
          compact
          color="green"
          onDismiss={this.handleDismiss}
          header="Lembrete!"
          content="O documento já foi assinado, grato!"
        />
      );
    }

    return (
      <p>
        <br />
        <i>A mensagem aparecerá em breve.</i>
        <br />
        <br />
      </p>
    );
  }
}

export default MessageIsApprovedBlock;
