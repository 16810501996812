import agentApi from "../apis/agentApi";

const authMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();

  if (action.type === "persist/REHYDRATE") {
    const { token } = state.auth;

    if (token) {
      agentApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }

  return result;
};

export default authMiddleware;
