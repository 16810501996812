import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import {
  Button,
  Divider,
  Header,
  Modal,
  ModalContent,
} from "semantic-ui-react";
import { fetchUsers } from "../../actions";

import { PDFObject } from "react-pdfobject";

import MessageNotArtBlock from "../../utils/messages/NotArtMessage";
import MessageIsApprovedBlock from "../../utils/messages/isApprovedMessage";

import agentApi from "../../apis/agentApi";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

class ReportModalClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      reports: [],
    };
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  componentDidMount() {
    const { startOpen } = this.props;

    if (startOpen) {
      this.handleOpen();
    }
  }

  processSignature = async () => {
    let res = await agentApi
      .post("api/reports/status", {
        status: 2,
        reports: [this.props.id],
      })
      .then((res) => {
        this.setState({ modalOpen: false });
        window.location.reload();
      });
    // .catch((err) => {
    //   this.setState({ data: err, isLoading: false });
    // });
  };

  handleClick = (data) => {
    return this.processSignature(data.status, data.reports);
  };

  render() {
    const {
      userData,
      currentUserId,
      file,
      title,
      observation,
      isApproved,
      movimentations,
    } = this.props;

    const checkUserIsArt = [];
    userData.map((user) => {
      if (user.id === currentUserId) {
        checkUserIsArt.push(user.isArt);
      }
    });

    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const signedBy = movimentations.map(function (item) {
      return item.user.name;
    });

    const signedIn = movimentations.map(function (item) {
      return ptBRFormatter.format(new Date(item.date));
    });

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        trigger={
          <button className="ui left labeled grey mini icon button">
            <i className="right eye icon" />
            Assinar
          </button>
        }
      >
        <Modal.Actions>
          {checkUserIsArt[0] && !isApproved && (
            <Button
              content="Assinar"
              labelPosition="right"
              icon="checkmark"
              onClick={this.handleClick}
              positive
            />
          )}

          {checkUserIsArt[0] && isApproved && (
            <>
              <MessageIsApprovedBlock className="ui six wide right aligned" />
            </>
          )}

          {!checkUserIsArt[0] && (
            <MessageNotArtBlock className="ui six wide right aligned" />
          )}
          <Button
            as="a"
            icon="print"
            color="yellow"
            href={apiUrl + `/${file}`}
            target="_blank"
            rel="noreferrer"
          />
          <Button icon="close" color="grey" onClick={this.handleClose} />
        </Modal.Actions>
        {isApproved ? (
          <ModalContent>
            <div className="ui visible green tiny message">
              <p>
                Assinado por: <strong>{signedBy}</strong>{" "}
                <span> em : {signedIn}</span>
              </p>
            </div>
          </ModalContent>
        ) : (
          ""
        )}

        <Modal.Header>
          <div>{title}</div>
        </Modal.Header>
        <Modal.Content>
          <PDFObject url={apiUrl + `/${file}`} />
          <Divider />
          <br />
          <Modal.Description>
            <Header>Observações:</Header>
            <p>{observation}</p>
            {/*<div>{findReport}</div>*/}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {checkUserIsArt[0] && !isApproved && (
            <Button
              content="Assinar"
              labelPosition="right"
              icon="checkmark"
              onClick={this.handleClick}
              positive
            />
          )}
          <Button
            as="a"
            icon="print"
            color="yellow"
            href={apiUrl + `/${file}`}
            target="_blank"
            rel="noreferrer"
          />
          <Button icon="close" color="grey" onClick={this.handleClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    // reports: _.orderBy(Object.values(state.reports), "endDate", "desc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchUsers,
    // fetchReports,
    // fetchReport,
  })(ReportModalClass)
);
