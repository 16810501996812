import _ from "lodash";
import {
  SELECTED_CONTRACT,
  CREATE_CONTRACT,
  FETCH_CONTRACTS,
  FETCH_CONTRACT,
  EDIT_CONTRACT,
  DELETE_CONTRACT,
} from "../actions/types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case SELECTED_CONTRACT:
      return { ...state, [action.payload.id]: action.payload };
    case FETCH_CONTRACTS:
      return { ...state, ..._.mapKeys(action.payload["hydra:member"], "id") };
    case FETCH_CONTRACT:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_CONTRACT:
      return { ...state, [action.payload.id]: action.payload };
    case EDIT_CONTRACT:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_CONTRACT:
      return _.omit(state, action.payload);

    default:
      return state;
  }
};
